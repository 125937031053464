import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './pages/main/main.component';
import { AboutComponent } from './pages/about/about.component';
import { FeaturesComponent } from './pages/features/features.component';
import { TokenomicsComponent } from './pages/tokenomics/tokenomics.component';
import { RoadmapComponent } from './pages/roadmap/roadmap.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from './pages/footer/footer.component'

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    AboutComponent,
    FeaturesComponent,
    TokenomicsComponent,
    RoadmapComponent,
    FooterComponent
  ],

  
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
