<div class="containers">


    <div class="entry">
        
        <div class="entry-text">
            <h1>TOKENOMICS</h1>
            <ul>
                <li>Name: TopCat</li>
                <li>Ticker: TopCat</li>
                <li>1,000,000,000 Tokens:</li>
                <li>50% burn</li>
                <li>20% Listing</li>
                <li>20% LGE (vested 1 week)</li>
                <li>10% Team / Marketing / Partnershipst</li>
                <li>3% Slippage required</li>
            </ul>   

        </div>  

        <div class="entry-img">
            <img src="../../../assets/images/tokenomics/tokenomics.png">
        </div>  
    </div>  

</div>