<div class="containers">
  <div class="entry">
    <div class="entry-img">
      <img src="../../../assets/images/pfp.png" />
    </div>

    <div class="entry-text">
      <h1>CA : 0x1234567891234567</h1>
    </div>
  </div>
</div>
