<!-- <nav>
    <a href="#1">1</a>
    <a href="#2">2</a>
    <a href="#3">3</a>
  </nav>
  
  <div class="scrolling-box">
    <section id="1"><app-about></app-about></section>
    <section id="2"><app-about></app-about></section>
    <section id="3"><app-about></app-about></section>
  </div> -->

<div class="nav-header">
  <div class="header-burger">
    <nav role="navigation">
      <div id="menuToggle">
        <input type="checkbox" id="burger-menu" />

        <span></span>
        <span></span>
        <span></span>

        <ul id="menu">
          <a href="#1" onclick="document.getElementById('burger-menu').click()"
            ><li>Boo</li></a
          >
          <!-- <a href="#2" onclick="document.getElementById('burger-menu').click()"
            ><li>Features</li></a
          >
          <a href="#3" onclick="document.getElementById('burger-menu').click()"
            ><li>Tokenomics</li></a
          >
          <a href="#4" onclick="document.getElementById('burger-menu').click()"
            ><li>Roadmap</li></a
          > -->
        </ul>
      </div>
    </nav>
  </div>

  <div class="image-container">
    <!-- <img src="../../../assets/images/pfp.png" /> -->
  </div>

  <div class="menu-container">
    <nav>
      <a href="#1">Boo</a>
      <!-- <a href="#2">Socials</a> -->
      <!-- <a href="#3">Tokenomics</a>
      <a href="#4">Roadmap</a> -->
    </nav>
  </div>

  <div class="links-container">
    <div>
      <img src="../../../assets/images/Solana.png" />
      <img src="../../../assets/images/ray.png" />
      <img src="../../../assets/images/Telegram Messenger New.png" />
      <img src="../../../assets/images/dextools.svg" />
      <img src="../../../assets/images/x.png" style="margin-left: 1.5em" />
    </div>
  </div>
</div>

<div class="main-container">
  <div class="landing">
    <img src="../../../assets/images/cover.png" />
  </div>

  <div class="scrolling-box">
    <section id="1">
      <app-about></app-about>
    </section>
    <section id="2">
      <app-features></app-features>
    </section>
    <!-- <section id="3">
      <app-tokenomics></app-tokenomics>
    </section>
    <section id="4">
      <app-roadmap></app-roadmap>
    </section> -->
  </div>

  <app-footer></app-footer>
</div>
