<div class="containers">
  <div class="header mt-10">
    <h1>{{ header }}</h1>
  </div>

  <div class="main-content mt-4">
    <p>
      Meet Boo, the Pomeranian pup who captured the hearts of millions around
      the world with his fluffy face and charismatic charm. Born in 2006, Boo
      quickly rose to internet stardom thanks to his adorable appearance, often
      likened to a real-life teddy bear.
    </p>
    <p>
      Boo's rise to fame began on social media platforms like Facebook, where
      his photos showcasing his perfectly groomed fur and expressive eyes went
      viral. His official Facebook page garnered millions of likes, making him
      one of the most famous dogs on the internet during his peak.
    </p>
    <p>
      Known affectionately as "The World's Cutest Dog," Boo's popularity
      extended beyond social media. He was featured in various media outlets,
      including television appearances and even a book titled "Boo: The Life of
      the World's Cutest Dog."
    </p>
    <p>
      Sadly, Boo passed away in 2019, leaving behind a legacy of joy and
      admiration. Despite his short life, Boo continues to be remembered fondly
      by fans worldwide, his photos and videos serving as timeless reminders of
      his endearing spirit and the joy he brought to so many.
    </p>
  </div>
</div>
