import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  maincontent: string = `Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
  Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
   when an unknown printer took a galley of type and scrambled it to make a 
   type specimen book. It has survived not only five centuries, b
   ut also the leap into electronic typesetting, remaining essent
   ially unchanged. It was popularised in the 1960s with the release of Letr
   aset sheets containing Lorem Ipsum passages, and more recently with desktop p
   ublishing software like Aldus PageMaker including versions of Lorem Ipsum`;

  header: string = 'Boo';

  constructor() {}

  ngOnInit(): void {}
}
